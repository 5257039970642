<template>
    <footer id="footer">
        <div class="container" v-if="datos_site">
            <h3 v-if="datos_site.institucional_titulo">{{ datos_site.institucional_titulo }}</h3>
            <p v-if="datos_site.footer_texto">{{ datos_site.footer_texto }}</p>
            <div class="social-links">
                <a v-if="datos_site.footer_link_twitter" :href="datos_site.footer_link_twitter" class="twitter" target="_blank">
                    <i class="bx bxl-twitter"></i>
                </a>
                <a v-if="datos_site.footer_link_facebook" :href="datos_site.footer_link_facebook" class="facebook" target="_blank">
                    <i class="bx bxl-facebook"></i>
                </a>
                <a v-if="datos_site.footer_link_instagram" :href="datos_site.footer_link_instagram" class="instagram" target="_blank">
                    <i class="bx bxl-instagram"></i>
                </a>
                <a v-if="datos_site.footer_link_linkedin" :href="datos_site.footer_link_linkedin" class="linkedin" target="_blank">
                    <i class="bx bxl-linkedin"></i>
                </a>
            </div>
            <div class="copyright">
                &copy; Copyright <strong><span v-if="datos_site.site_name">{{ datos_site.site_name }}</span></strong>. All Rights Reserved
            </div>
            <div class="credits">
                <a href="https://soportegmc.wixsite.com/capra">Soluciones Informáticas Capra</a> Powered by <a href="https://lemonsystems.com.ar/">Lemonsystems</a>
            </div>
        </div>
    </footer>
    
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default{
    name: 'FooterComponent',
    components: {
    },
    setup(){
        const store = useStore()
        const datos_site = computed(() => store.state.datos.site)
        return {datos_site}
    }
}
</script>
