<template>
    <nav v-if="menu" id="navbar" :class="{ 'navbar-mobile': isActive }" class="navbar order-last order-lg-0">
        <ul>
            <template v-for="menuitem of menu" v-bind:key="menuitem.id_module">
                <li v-if="menuitem.public == 1" :class="'nav-link ' + (menuitem.children.length > 0 ? 'dropdown ' : 'nav-link ') + isActiveRouter(menuitem.module)">
                    <router-link @click="navToogleLink" :class="{ active: isActiveRouter(menuitem.module) }" class="dash-nav-item" :to="'/' + (menuitem.module != 'inicio' ? menuitem.module : '')">
                        <span>{{ menuitem.module_menu }}</span>
                        <i v-if="menuitem.children.length > 0" class="bi bi-chevron-down"></i>
                    </router-link>
                    <ul v-if="menuitem.children.length > 0">
                        <li v-for="menusub of menuitem.children" v-bind:key="menusub.id_module">
                            <router-link @click="navToogleLink" :to="'/' + menusub.module">{{ menusub.module_menu }}</router-link>
                        </li>
                    </ul>	
                </li>
            </template>
        </ul>
        <i @click.prevent="navToogle" class="bi bi-list mobile-nav-toggle rounded-0 p-1"></i>
    </nav><!-- .navbar -->
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

export default{
    name: 'Menu',
    setup(){
        const store = useStore()
        const menu = computed(() => store.state.menu)
        const isActive = ref(false)

        const navToogle = () => {
            isActive.value = !isActive.value
        }
        
        const navToogleLink = () => {
            if(isActive.value){
                isActive.value = false
            }
        }
        
        return {
            menu,
            navToogle,
            navToogleLink,
            isActive
        }
    },
    methods: {
        isActiveRouter(name) {
            return this.$route.name == name ? 'active' : '';
        }
    }
}
</script>
