import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true, // Indicates that this route requires authentication
    }
  },
  {
    path: '/institucional',
    name: 'institucional',
    meta: { module: 'institucional' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InstitucionalView.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    meta: { module: 'contacto' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactoView.vue')
  },
  {
    path: '/novedades',
    name: 'novedades',
    meta: { module: 'novedades' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NovedadesView.vue')
  },
  {
    path: '/novedades/:id?',
    name: 'novedad',
    meta: { module: 'novedades' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NovedadesView.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    meta: { module: 'eventos' },
    component: () => import(/* webpackChunkName: "about" */ '../views/EventosView.vue')
  },
  {
    path: '/eventos/:id?',
    name: 'evento',
    meta: { module: 'eventos' },
    component: () => import(/* webpackChunkName: "about" */ '../views/EventosView.vue')
  },
  {
    path: '/proyecto',
    name: 'proyecto',
    meta: { module: 'proyecto' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProyectoEducativoView.vue')
  },
  {
    path: '/inscripciones',
    name: 'inscripciones',
    meta: { module: 'inscripciones' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AdmisionesView.vue')
  },
  {
    path: '/administracion',
    name: 'administracion',
    meta: { module: 'administracion' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AdministracionView.vue')
  },
  {
    path: '/autogestion',
    name: 'autogestion',
    meta: { module: 'autogestion' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AutogestionView.vue')
  },
  {
    path: '/cv',
    name: 'cv',
    meta: { module: 'cv' },
    component: () => import(/* webpackChunkName: "about" */ '../views/EnviaCurriculumView.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Store route metadata in Vuex store
  store.dispatch('setRouteMeta', to.meta)
  next()
})

export default router
