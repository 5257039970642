<template>
    <div :class="class" class="section-title pb-0">
        <h2>{{ title }}</h2>
    </div>
</template>

<script>
export default{
    name: 'TitleComponent',
    props: {
        'title':{
            type: String,
            default: 'Sin titulo'
        },
        'class':{
            type: String
        }
    }
}
</script>