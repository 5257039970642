<template>
    <header id="header" class="fixed-top pickgradient">
        <div class="container-fluid">

            <div class="row justify-content-center">
                <div class="col-xl-9 d-flex align-items-center justify-content-lg-between">
                    <router-link to="/" class="logo logo-white me-auto me-lg-0">
                        <img src="/assets/images/logo_white.svg" alt="" class="ximg-fluid">
                    </router-link>
                    <router-link to="/" class="logo logo-blue me-auto me-lg-0">
                        <img src="/assets/images/logo_blue.svg" alt="" class="ximg-fluid">
                    </router-link>
                    <MenuComponent />
				</div>
            </div>

        </div>
    </header><!-- End Header -->
    <HeaderImagesComponent v-if="$route.name != 'home'" :module="currentRoute" />
</template>

<script>
import { useStore } from 'vuex'
//import { useRoute } from 'vue-router'
import { computed } from 'vue'
import MenuComponent from './MenuComponent.vue'
import HeaderImagesComponent from './general/HeaderImagesComponent.vue'
export default{
    name: 'Header',
    components: {
        MenuComponent,
        HeaderImagesComponent
    },
    setup(){
        const store = useStore()
        //const currentRoute = computed(() => useRoute().name)
        const currentRoute = computed(() => {
            const routeMeta = store.state.routeMeta;
            return routeMeta && routeMeta.module ? routeMeta.module : null
        })
        return {
            currentRoute
        }
    }
}
</script>
