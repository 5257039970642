<template>
  <div id="header_image" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner">
          <div v-if="headers" v-for="(item, index) of headers" v-bind:key="item.id_imagen"  :class="index == 0 ? 'active' : ''" class="carousel-item">
              <img width="1920" height="400" :src="'/images/cabeceras/'+module+'/'+item.imagen" class="d-block w-100" />
          </div>
          <div v-else class="carousel-item active">
              <img src="/assets/images/cabecera_default.jpg" class="d-block w-100" />
          </div>
      </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineProps, computed, onMounted } from 'vue'

export default{
  name: 'HeaderImagesComponent',
  props: {
    // Define your props
    module: {
      type: String
    }
  },
  setup(props){

    const store = useStore()
    const currentRoute = computed(() => {
      return useRoute().name
    })

    const propModule = computed(() => {
      const valor = props.module
      return valor
    })

    const headers = computed(() => {
      const imagenes = store.state.datos.cabeceras
      return imagenes && imagenes[propModule.value] ? imagenes[propModule.value] : null
    })
    
    return {
      propModule,
      headers            
    }

  },
  mounted () {
    // Bootstrap x Vue bug that stops the carousel working on navigating
    //new bootstrap.Carousel($("#exampleSelector")).cycle()
    new bootstrap.Carousel(document.getElementById('header_image'), {
      interval: 2000 // Set the interval for auto-sliding, adjust as needed
    }).cycle()
  }
}
</script>