// common.utils.js
const moment = require('moment-timezone')

// Function returns day number of a date
const getDay = (fecha) => {
  return moment(fecha).tz('UTC').format('D');
}

// Function returns year of a date
const getYear = (fecha) => {
  return moment(fecha).tz('UTC').format('YYYY');
}

const formatDate = (dateToformat, formatDate) => {
  return moment(dateToformat).tz('UTC').format(formatDate)
}

// Function to truncate text
const truncate = (text, length, clamp) => {
  clamp = clamp || '...';
  return text && text.length > length ? text.slice(0, length) + clamp : text
}

// Function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Function to generate a random number within a range
const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const formatBytes = (bytes) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

const findMenuItemByName = (menu, name) => {
  // Iterate through the menu items
  for (const item of menu) {
    // If the current item's name matches the search name, return it
    if (item.module === name) {
      return item;
    }
    // If the current item has children, recursively search through them
    if (item.children) {
      const foundInChildren = findMenuItemByName(item.children, name);
      if (foundInChildren) {
        return foundInChildren;
      }
    }
  }
  // If no match is found, return null
  return null;
}

// Export the utility functions
module.exports = {
  findMenuItemByName,
  formatBytes,
  getDay,
  getYear,
  formatDate,
  truncate,
  capitalizeFirstLetter,
  getRandomNumber
}