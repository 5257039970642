<template>
  <template v-if="home">
    <section id="hero" class="d-flex flex-column justify-content-center">

      <template v-if="home.site_inicio && home.site_inicio.video">
        <video @loadedmetadata="videoLoaded" playsinline autoplay="autoplay" loop="true" muted="true" poster="" preload="auto" alt="slidebg1" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
          <source :src="'/videos/inicio/' + home.site_inicio.video" type="video/mp4">
        </video>
        <div v-if="home.site_inicio_accesos && home.site_inicio_accesos.length" class="container-boxes">
          <div v-for="item_acceso of home.site_inicio_accesos" v-bind:key="item_acceso.id_acceso" class="box d-flex flex-column justify-content-between">
            <a :href="isURL(item_acceso.link) ? item_acceso.link : item_acceso.link" class="d-flex flex-column align-items-center h-100" :target="isURL(item_acceso.link) ? '_BLANK' : ''" >
              <div :class="'box-text bx bx-' + item_acceso.icono"></div>
              <div class="box-text text-uppercase">{{ item_acceso.titulo }}</div>
              <div class="small-text d-flex flex-grow-1 align-items-center">
                {{ item_acceso.texto }}
              </div>
            </a>
          </div>
        </div>
      </template>

      <div class="container">
        <div class="row justify-content-center">
            <div id="carousel-quotes" class="carousel text-center" data-bs-ride="carousel">
              <!-- h1 style="font-size: 3em">En construcción</h1-->
                
              <div class="carousel-inner">
                <template v-if="home.site_inicio_quotes && home.site_inicio_quotes.length">
                    <div  v-for="(quote,index) of home.site_inicio_quotes" v-bind:key="quote.id_quote" :class="index == 0 ? 'active' : ''" class="carousel-item" data-bs-interval="10000">
                      <div class="xcarousel-caption xd-none xd-md-block">
                        <h1 class="quotes-anim">{{ quote.quote }}</h1>
                        <h2 class="quotes-anim">{{ quote.author }}</h2>
                      </div>
                    </div>
                </template>
              </div>					
            </div>
        </div>
      </div>
      <div class="home-bottom">
        <div class="container text-center">
          <div class="move bounce">
            <a href="#about" class="bi bi-chevron-down">
            </a>
          </div>
        </div>
      </div>
    </section><!-- End Hero -->

    <main id="main">

      <!-- ======= About Us Section ======= -->
      <SectionComponent v-if="home?.site_inicio" :id="'about'" :class="'about'" :title="home.site_inicio.institucional_titulo" :subtitle="home.site_inicio.institucional_texto" :text="home.site_inicio.institucional_desarrollo">
        <div class="d-flex justify-content-center">
          <ButtonComponent :href="'/institucional/'" :caption="'Leer Más ...'" :hollow="true"/>
        </div>
      </SectionComponent>
      <!-- End About Us Section -->

      <!-- ======= Services Section ======= -->
      <!--SectionComponent v-if="home?.site_inicio" :id="'services'" :class="'services'" :title="home.site_inicio.accesos_titulo" :text="home.site_inicio.accesos_texto">
        <div v-if="home.site_inicio_accesos && home.site_inicio_accesos.length" class="row">
          <div v-for="item_acceso of home.site_inicio_accesos" v-bind:key="item_acceso.id_acceso" class="col-lg-4 col-md-6 d-flex align-items-stretch mb-3">
            <div class="icon-box">
              <a :href="isURL(item_acceso.link) ? item_acceso.link : item_acceso.link"  :target="isURL(item_acceso.link) ? '_BLANK' : ''" >
                <div class="icon"><i :class="'bx bx-' + item_acceso.icono"></i></div>
                <h4>{{ item_acceso.titulo }}</h4>
                <p>{{ item_acceso.texto }}</p>
              </a>
            </div>							
          </div>
        </div>
      </SectionComponent-->
      <!-- End Services Section -->

      <!-- ======= Cta Section ======= -->
      <SectionComponent v-if="home?.site_novedades || home?.site_novedades_destacadas" :title="home.site_inicio.novedades_titulo" :text="home.site_inicio.novedades_texto">
        <div class="container">
          <div v-if="home.site_novedades_destacadas && home.site_novedades_destacadas.length" class="noticias">
            <NoticiasDestacadasComponent :noticias="home.site_novedades_destacadas"/>
          </div>
          <NoticiasComponent :noticias="home.site_novedades"/>
          <div class="d-flex justify-content-center">
            <ButtonComponent :href="'/novedades/'" :caption="'Ver más...'" :hollow="true"/>
          </div>
        </div>
      </SectionComponent>
      

      <SectionComponent v-if="home?.site_eventos && home.site_eventos.length" :title="home.site_inicio.eventos_titulo" :text="home.site_inicio.eventos_texto">
        <EventosComponent :eventos="home.site_eventos" :showText="false"/>
        <div class="d-flex justify-content-center">
          <ButtonComponent :href="'/eventos/'" :caption="'Ver más...'" :hollow="true"/>
        </div>
      </SectionComponent>
      
      
      <!-- ======= Album Section ======= -->
      <SectionComponent v-if="home?.site_inicio_album" :id="'album'" :class="'album'" :title="home.site_inicio.album_titulo ? home.site_inicio.album_titulo : 'Galería De Fotos'" :text="home.site_inicio.album_texto">
        <AlbumComponent :albums="home.site_inicio_album"/>
      </SectionComponent>
      <!-- End Album Section -->

      <!-- ======= Contact Section ======= -->
      <SectionComponent v-if="home?.site_inicio" :id="'contact'" :class="'contact'" :title="home.site_inicio.contacto_titulo" :text="home.site_inicio.contacto_texto">
        <div class="container">
          <div class="row mt-5">
            <div class="col-lg-4">
              <DireccionesComponent />
            </div>
            <div class="col-lg-8 mt-5 mt-lg-0">
              <FormConsultaComponent />
            </div>
          </div>
        </div>
      </SectionComponent>
      <GoogleMapsComponent v-if="home?.site_inicio && home.site_inicio.map" :src="home.site_inicio.map" />
      
    </main><!-- End #main -->	
  </template>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import MesComponent from '../components/general/MesComponent.vue'
import TitleComponent from '../components/general/TitleComponent.vue'
import SectionComponent from '../components/general/SectionComponent.vue'
import ButtonComponent from '../components/general/ButtonComponent.vue'
import NoticiasComponent from '../components/noticias/NoticiasComponent.vue'
import NoticiasDestacadasComponent from '../components/noticias/NoticiasDestacadasComponent.vue'
import EventosComponent from '../components/eventos/EventosComponent.vue'
import GoogleMapsComponent from '../components/general/GoogleMapsComponent.vue'
import DireccionesComponent from '../components/general/DireccionesComponent.vue'
import FormConsultaComponent from '../components/general/FormConsultaComponent.vue'
import AlbumComponent from '../components/general/AlbumComponent.vue'

export default {
  name: 'HomeView',
  components: {
    MesComponent,
    TitleComponent,
    SectionComponent,
    ButtonComponent,
    NoticiasComponent,
    NoticiasDestacadasComponent,
    EventosComponent,
    GoogleMapsComponent,
    DireccionesComponent,
    FormConsultaComponent,
    AlbumComponent
  },
  setup(){
    //const home = computed(() => store.state.home)
    // Define a reactive variable to store your data
    const home = ref(null)
    const store = useStore()
    
    const videoLoaded = () => {
      store.commit('setLoading', false)
    }
    const isURL = (str) => {
      return (str.indexOf("http://") == 0 || str.indexOf("https://") == 0)
    }
    const fetchHome = async () => {
      store.commit('setLoading', true)
      try {
        const response = await fetch('home', { 'auth': true })
        const responseData = await response.json()
        // Assign the fetched data to the reactive variable
        home.value = responseData
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        store.commit('setLoading', false)
      }
    }

    // Call the fetchData function when the component is mounted
    onMounted(() => {
      store.commit('setLoading', true)
      fetchHome()
    })

    return {home, isURL, videoLoaded}
  }

}
</script>

<style scoped>

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: to darken the video */
}
.container-boxes {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  z-index: 1;
  padding-bottom: 55px; /* Adjust as needed */
}
.container-boxes a {
  overflow: hidden;
}
.box {
  width: 14vw;
  height: 188px;
  color: white;
  margin: 18px 16px 36px 16px;
  border-radius: 5%;
  background-color: #05264f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.box-text {
  text-align: center;
  text-transform: uppercase;
  color: white;
  padding-top: 10px;
}
.small-text {
  text-align: center;
  font-size: 12px;
  color: gray;
  text-transform: uppercase;
}



@media (max-width: 1600px) {
  .box {
    width: 13vw;
    height: 195px;
  }
  .circle {
    width: 93px;
    height: 93px;
    font-size: 2.5em;
  }
}

@media (max-width: 1024px) {
  .box {
    width: 20vw;
    height: 188px;
  }
  .circle {
    width: 83px;
    height: 83px;
    font-size: 2.5em;
  }
  #hero {
    height: 52vw;
  }
  #hero video{
    height: 52vw;
  }
}

@media (max-width: 768px) {
  .box {
    width: 22vw;
    height: 168px;
    margin: 18px 6px 36px 6px;
    font-size: 0.8em;    
  }
  
  .circle {
    width: 80px;
    height: 80px;
    font-size: 2.2em;
  }  
}
@media (max-width: 640px) {
  .box {
    width: 27vw;
    height: 150px;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    font-size: 2.2em;
  }
}
@media (max-width: 480px) {
  .box {
    width: 27vw;
    height: 30vw;
    margin: 18px 6px 36px 6px;
    font-size: 0.8em;    
  }
  
  .circle {
    width: 11vw;
    height: 11vw;
    font-size: 2.2em;
  }

  #hero {
    height: 72vw;
  }
  #hero video{
    height: 72vw;
  }
}
</style>