<template>
    <div :v-if="src">
        <iframe :style="'border:0; width: 100%; height: ' + height + 'px; margin-bottom: -6px;'" :src="src" frameborder="0" allowfullscreen></iframe>
    </div>
</template>

<script>

export default{
    name: 'GoogleMapsComponent',
    props: {
        'src':{
            type: String
        },
        'height':{
            type: Number,
            default: 350
        }
    }
}
</script>